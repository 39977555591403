// 代理財務報表
import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import language from '../../locale/agentFinanceReport'
import moment from 'moment'

import a from '../../actions'
import {
  getReportByFilter,
  getCurrencyOptions,
  getLanguage,
  getTimeZone,
  getGameList,
  getIsFetchingByFilter,
} from '../../reducers'
import { qsParse, formatDateByGroupBy, formatMoney } from '../../helpers'
import { getSavedState } from '../../helpers/storage'

import Container from '../../components/Container'
import GridItemFull from '../../components/grids/GridItemFull'
import Paper from '../../components/Paper'
import ReportSummaryCard from '../../components/ReportSummaryCard'
import AgentFinanceReportForOwnerForm from '../../components/forms/AgentFinanceReportForOwnerForm'
import DataTable from '../../components/tables/DataTable'
import { getIsGroupOwner } from '../../reducers/auth'
import AgentFinanceReportForAdminForm from '../../components/forms/AgentFinanceReportForAdminForm'

class AgentFinanceReportForOwner extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rowsPerPage: 30,
      formatReports: [],
      formatSummaryReports: [],
      columns: [],
      payload: null,
      reportPagination: null,
      needFetchOnMount: false,
      isSearched: false,
      isDownloaded: false,
    }
  }

  componentDidMount () {
    const { i18n } = this.props

    i18n.addResourceBundle('en', 'agentFinanceReport', language.en)
    i18n.addResourceBundle('cn', 'agentFinanceReport', language.cn)
  }

  componentDidUpdate (props, prevState) {
    const {
      reports = {},
      summaryReports,
      language,
      timezone,
    } = this.props

    // 切換時區要自動重載資料，並提示使用者
    // 判斷 component 若為需預先載入資料（needFetchOnMount）或者有搜尋過(prevState.isSearched)才能重撈。
    // 白話意思是使用者必須先點搜尋一次，或者這個 Component 在 Mount 的時候就會載入資料的類型，這時切換時區才會重新撈取資料）
    if ((prevState.needFetchOnMount || prevState.isSearched) && props.timezone !== timezone) {
      this.handleChangeTimeZone(timezone)
    }

    if (props.reports !== reports) {
      this.setFormatReports(reports.data)
      this.setReportPagination(reports.pagination)
    }

    if (props.summaryReports !== summaryReports) {
      this.setFormatSummaryReports(summaryReports)
    }

    if (props.language !== language) {
      this.setColumns()
    }
  }

  setColumns = () => {
    const { t } = this.props

    this.setState({
      columns: [
        {
          name: t('datetime'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('parentid'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('currency'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('parentincome'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => {
              const v = formatMoney(value)

              return parseFloat(value) < 0 ? (
                <span style={{ color: 'red' }}>{v}</span>
              ) : (v)
            },
          },
        },
      ],
    })
  };

  setReportPagination = (data = {}) => {
    this.setState({
      reportPagination: data,
    })
  };

  setFormatReports = (data = []) => {
    this.setState({
      formatReports: data.map(item => [
        item.datetime,
        item.parentAcc,
        item.currency,
        item.parentincome,
      ]),
    })
  };

  setFormatSummaryReports = data => {
    this.setState({
      formatSummaryReports: data
        ? [
          {
            name: 'TOTAL'.toUpperCase(),
            data: Object.keys(data).map(key => ({
              key,
              value: data[key],
            })),
          },
        ]
        : [],
    })
  };

  handleDownload = payload => {
    const { downloadReport, timezone, t } = this.props

    downloadReport({ ...payload, timezone }, {
      filter: 'agentFinanceReport',
      fileName: t('agentFinanceReport', { ns: 'sidebar' }),
      isDownloaded: this.state.isDownloaded,
    })

    if (!this.state.isDownloaded) {
      this.setState({ isDownloaded: true })
    }
  }

  handleSubmit = values => {
    const payload = {
      ...values,
      pagesize: this.state.rowsPerPage,
      startDate: formatDateByGroupBy(values.startDate, values.groupby),
      endDate: formatDateByGroupBy(values.endDate, values.groupby),
    }
    this.setState({ isDownloaded: false })

    return this.onSubmit(payload)
  };

  handleChangeTimeZone = async timezone => {
    const { payload } = this.state

    try {
      await this.onSubmit({ ...payload, timezone })
    } catch (err) {}
  };

  handleChangePage = page => {
    const { payload } = this.state

    return this.onSubmit({ ...payload, nowpage: page + 1 })
  };

  handleChangeRowsPerPage = rowsPerPage => {
    const { payload } = this.state

    this.setState({ rowsPerPage })

    return this.onSubmit({ ...payload, pagesize: rowsPerPage, nowpage: 1 })
  };

  fetchReport = payload => {
    const { fetchReport, timezone } = this.props
    return fetchReport({ ...payload, timezone }, { filter: 'agentFinanceReport' })
  };

  onSubmit = payload => {
    const { gameType, ...others } = payload
    const gameTypeObj = {
      all: ['table', 'slot', 'fish', 'arcade', 'lotto', 'live'],
      allGame: ['slot', 'fish', 'arcade'],
    }
    const gameTypeTemp = gameTypeObj[gameType] || [gameType]
    this.setState({ payload: { ...others, gameType: gameTypeTemp }, isSearched: true }, () => {
      this.setFormatReports([])
      this.setColumns()
    })

    return this.fetchReport({ ...others, gameType: gameTypeTemp })
  };

  render () {
    const {
      filterOwnerAccount,
      filterParentAccount,
      filterGroupBy,
      currencyOptions,
      isFetching,
      canDownload,
      timezone,
      isgroupowner,
      allGamesOptions,
    } = this.props
    const {
      formatReports,
      formatSummaryReports,
      reportPagination,
      columns,
      isSearched,
      payload,
      rowsPerPage,
    } = this.state

    const startDate = new Date(moment().utcOffset(timezone * 60).startOf('date').format('YYYY/MM/DD HH:mm:ss'))
    const endDate = new Date(moment().utcOffset(timezone * 60).endOf('date').format('YYYY/MM/DD HH:mm:ss'))

    const fromProps = {
      ownerAcc: filterOwnerAccount,
      parentAcc: filterParentAccount,
      commonProps: {
        gameType: 'all',
        currency: '',
        groupby: filterGroupBy,
        startDate,
        endDate,
        pagesize: rowsPerPage,
        nowpage: 1,
        currencyOptions: () => currencyOptions,
        allGamesOptions: () => allGamesOptions,
        onSubmit: this.handleSubmit,
        isFetching,
      },
    }

    return (
      <Container>
        <GridItemFull>
          <Paper>
            {isgroupowner
              ? <AgentFinanceReportForAdminForm
                withGameType
                ownerAcc={fromProps.ownerAcc}
                parentAcc={fromProps.parentAcc}
                {...fromProps.commonProps}
              />
              : <AgentFinanceReportForOwnerForm
                withGameType
                withGameCode
                parentAcc={fromProps.parentAcc}
                {...fromProps.commonProps}
              />
            }
          </Paper>
        </GridItemFull>

        {payload && payload.currency !== '' && (
          <GridItemFull>
            <ReportSummaryCard data={formatSummaryReports} />
          </GridItemFull>
        )}

        { isSearched &&
        <GridItemFull>
          <DataTable
            data={formatReports}
            columns={columns}
            pagination={reportPagination}
            payload={payload}
            canDownload={canDownload}
            downloadReport={this.handleDownload}
            options={{
              rowsPerPage,
              onChangePage: this.handleChangePage,
              onChangeRowsPerPage: this.handleChangeRowsPerPage,
            }}
          />
        </GridItemFull>
        }
      </Container>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const isgroupowner = getIsGroupOwner(state)
  const search = location ? qsParse(location.search) : null

  const currencyOptions = getCurrencyOptions(state)

  const filterOwnerAccount = search.owner ? search.owner : ''
  const filterParentAccount = search.agent ? search.agent : ''
  const filterGroupBy = search.groupBy ? search.groupBy : 'day'
  const timezone = getSavedState('timezone') || getTimeZone(state)
  const isFetching = getIsFetchingByFilter(state, 'agentFinanceReport')

  const language = getLanguage(state)
  const allGamesOptions = getGameList(state, language)
  const reports = getReportByFilter(state, 'agentFinanceReport')
  const summaryReports = getReportByFilter(
    state,
    'agentFinanceReportSummary',
  )

  // excel button 顯示及 excel 功能是否開啟
  const canDownload = reports.candownload

  return {
    isgroupowner,
    canDownload,
    filterOwnerAccount,
    filterParentAccount,
    filterGroupBy,
    currencyOptions,
    search,
    reports,
    language,
    timezone,
    summaryReports,
    isFetching,
    allGamesOptions,
  }
}

export default connect(
  mapStateToProps,
  {
    downloadReport: a.report.download,
    fetchReport: a.report.fetchReport,
  },
)(withTranslation('agentFinanceReport')(AgentFinanceReportForOwner))
