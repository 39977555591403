import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import a from '../../actions'
import { qsParse } from '../../helpers'
import language from '../../locale/gameDetailLink'
import { getLanguage, getBrandInfo, getUser, getUserInfo } from '../../reducers'

import Container from '../../components/Container'
import Paper from '../../components/Paper'
import GridItem from '../../components/grids/GridItem'
import GameDetailLinkForm from '../../components/forms/GameDetailLinkForm'

class GameDetailLink extends Component {
  componentDidMount () {
    const { i18n } = this.props

    i18n.addResourceBundle('en', 'gameDetailLink', language.en)
    i18n.addResourceBundle('cn', 'gameDetailLink', language.cn)
  }

  onSubmit = async values => {
    this.props.fetchGameDetailLink(values, { gameType: values.gameType })
  };

  render () {
    const { brandInfo } = this.props
    const { role } = this.props
    const { userInfo } = this.props
    const { permit } = this.props

    return (
      <Container>
        <GridItem form>
          <Paper>
            <GameDetailLinkForm
              role={role}
              permit={permit}
              roundid=""
              gamehall={userInfo.brand}
              gameType="slotAndTable"
              host="0" // china line
              onSubmit={this.onSubmit}
              brand={userInfo.brand}
            />
          </Paper>
        </GridItem>
      </Container>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const search = location ? qsParse(location.search) : null
  const language = getLanguage(state)

  // brand
  const brandInfo = getBrandInfo(state)
  const user = getUser(state)
  const userInfo = getUserInfo(state)
  return {
    role: user.role,
    permit: user.permit,
    user,
    search,
    language,
    brandInfo,
    userInfo,
  }
}

export default connect(
  mapStateToProps,
  {
    fetchGameDetailLink: a.game.fetchGameDetailLink,
  },
)(withTranslation()(GameDetailLink))
