import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import actionType from '../actions/actionType'
import { api, wsClient } from '../services'
import { saveState, removeState } from '../helpers/storage'
import actions from '../actions'
import { getIsLogin, getIsLogout } from '../reducers'

/**
 * Effect to fetch user info.
 */
export function * fetchUserInfo () {
  const res = yield call(api.get, api.BO, '/user/info')
  const { data: { data: { parentacc, account } } } = res
  return parentacc || account
}

/**
 * Effect to login
 */
export function * login ({ payload }) {
  // 1. Check is user already login
  if (yield select(getIsLogin)) {
    return
  }

  try {
    // 2. Dispatch login request to Store
    yield put(actions.auth.loginRequest())

    // 3. Call API: login
    const { data: { data: { usertoken } } } = yield call(api.post, api.BO, '/login', payload)

    // 4. Set user token to BO header
    yield call(api.setHeaders, api.BO, { usertoken })
    yield call(api.setHeaders, api.BO2, { usertoken })

    // 5. Creact RP client instance
    yield call(api.setHeaders, api.RP, { usertoken: usertoken, Authorization: usertoken })

    // 6. Save usertoken and last login info to localStorage
    saveState('token', usertoken)
    saveState('lastLogin', {
      username: payload.username,
      type: payload.type,
    })

    // 7. Call API: get `parentAcc`
    // fish comment: JWT 只有存 parent ID 沒有存 Account
    const parentAcc = yield call(fetchUserInfo)
    yield put(actions.user.fetchInfo())
    // 8. Dispatch login success to Store
    yield put(actions.auth.loginSuccess({ parentAcc, token: usertoken }))
  } catch (err) {
    // Dispatch login failure to store
    yield put(actions.auth.loginFailure())
    // Announce API error
    yield put(actions.msgs.add({
      replaceWithI18nText: 'loginErrorMessage',
      variant: 'warning',
    }))
  }
}

/**
 * Effect to logout
 */
export function * logout () {
  // 1. Check is user already logout
  if (yield select(getIsLogout)) return

  try {
    // 2. Logout request started
    yield put(actions.auth.logoutRequest())

    // 3. Call API: logout (不管api回傳
    api.post(api.BO, '/logout').catch()

    // 4. Remove user token from localStorage
    yield call(removeState, 'token')

    // 5. Replace URL to '/'
    yield put(replace('/'))

    // 6. Disconnect ws
    yield call(wsClient.Disconnect)

    // 7. Logout success
    yield put(actions.auth.logoutSuccess())

    // 8. clear siteFeature state
    yield put(actions.siteFeature.reset())
  } catch (err) {
    // Dispatch login failure to store failure
    yield put(actions.auth.logoutFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

// effect setUserByToken
// 重整時使用，重新建立 api 實體及設定 header
export function * setUserByToken ({ payload: { token } }) {
  try {
    // 1. Set user token: 有效防止先出現登入畫面的問題
    yield put(actions.auth.setUserByTokenRequest())

    // 2. Call API: get `parentAcc`
    // fish comment: JWT 只有存 parent ID 沒有存 Account
    const parentAcc = yield call(fetchUserInfo)
    yield put(actions.user.fetchInfo())
    // 3. Set user token and parentAcc
    yield put(actions.auth.setUserByTokenSuccess({ token, parentAcc }))
  } catch (err) {
    // Dispatch set user by token to store failure
    yield put(actions.auth.setUserByTokenFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
};

const effects = [
  takeLatest(actionType.auth.login, login),
  takeLatest(actionType.auth.logout, logout),
  takeLatest(actionType.auth.setUserByToken, setUserByToken),
]

export default effects
