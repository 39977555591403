import React, { useState, useEffect, useCallback } from 'react'
import { api } from '../../services'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import OutlinedTextField from '../text-fields/OutlinedTextField'
import RadioTextField from '../text-fields/RadioTextField'

import { renderFields } from './helper'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    roundid: Yup.string().required('required'),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'GameDetailLinkForm',
  enableReinitialize: true,
})

const GameDetailLinkForm = props => {
  const { role, theme, isSubmitting, classes, t, brand, title = 'slot', permit } = props
  const [brandList, setBrandList] = useState([])
  const gameTypeOptions = [
    { label: t('slotAndTable'), value: 'slotAndTable' },
  ]

  // 第二品牌初期沒有體彩
  if (brand !== 'ae') {
    gameTypeOptions.push({ label: t('sportAndLotto'), value: 'sportAndLotto' })
  }

  const fields = [
    {
      name: 'roundid',
      label: t('roundid'),
      fullWidth: true,
      Field: OutlinedTextField,
    },

    {
      Field: RadioTextField,
      name: 'gamehall',
      label: t('gamehall'),
      fullWidth: true,
      options: brandList.map((el) => ({
        label: t(el),
        value: el,
      })),
    },
    {
      Field: RadioTextField,
      name: 'gameType',
      label: t('gameType'),
      fullWidth: true,
      options: gameTypeOptions,
    },
    {
      Field: RadioTextField,
      name: 'host',
      label: t('lineInformation'),
      fullWidth: true,
      options: [
        { label: t('chinaLine'), value: '0' },
        { label: t('internationalLine'), value: '1' },
      ],
    },
  ]

  const fetchBrandList = useCallback(async () => {
    const { data: { data: brandOptions } } = await api.get(api.BO, '/brand/list')
    const { data: { data: userInfo } } = await api.get(api.BO, '/user/info')

    const isAdmin = role === 'admin' || (role === 'cs' && permit === 'admin')

    const brandSelectOptions = isAdmin ? brandOptions : [userInfo.brand]

    setBrandList(brandSelectOptions)
  }, [role, permit])

  useEffect(() => {
    fetchBrandList()
  }, [])

  return (
    <Form>
      <h3 style={ theme.customer.H3Style }>{brand.toUpperCase()} {t(`${title}Title`)}</h3>

      {renderFields(fields, props)}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('search', { ns: 'common' })}
      </Button>
    </Form>
  )
}

export default formikEnhancer(
  withTranslation('gameDetailLink')(withStyles(styles, { withTheme: true })(GameDetailLinkForm)),
)
