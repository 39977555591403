import { call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'
import { saveState } from '../helpers/storage'

/**
 * Effect to set langauge
 */
// 思考中：1, 3 是否可再調整？（保留一個
// 思考的不錯，下次別想了，這樣就好 (by AI)
export function * set ({ payload: { language } }) {
  // 1. 儲存 language 到 localstorage
  yield call(saveState, 'language', language)

  // 2. Set API Client header: x-lang
  yield call(api.setHeaders, api.BO, { lang: language })

  // 3. 通知 store language 已經更新了
  yield put(actions.language.setSuccess({ language }))
}

const effects = [
  takeLatest(actionType.language.set, set),
]

export default effects
