const rawData = {
  datetime: ['Date', '日期'],
  parentid: ['Agent Account', '代理帐号'],
  currency: ['Currency', '币别'],
  parentincome: ['Agent Profit', '代理损益金额'],
  donate: ['Donate', '打赏金额'],
  noMatch: ['Sorry, no matching records found', '没有找到匹配的资料'],

  brand: ['Brand', '品牌'],
  ownerAcc: ['General Agent Account', '总代理帐号'],
  parentAcc: ['Agent Account', '代理帐号'],
  groupby: ['Settlement Interval', '结算周期'],
  hour: ['Hour', '时'],
  day: ['Day', '日'],
  month: ['Month', '月'],
  year: ['Year', '年'],
  gameType: ['Game Type', '游戏类型'],
  allGame: ['Probability Game', '机率游戏'],
  table: ['Table Battle', '牌桌对战'],
  slot: ['Slot', '老虎机'],
  fish: ['Fish', '渔机'],
  arcade: ['Arcade', '街机'],
  lotto: ['Lotto', '彩票'],
  live: ['Live', '真人视讯'],
  all: ['All', '全部'],
  bet: ['Bet', '总押金'],
  gameresultpoints: ['Game Result', '游戏结果'],
  gamecode: ['Game Code', '游戏代号'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
